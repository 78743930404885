import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidelines"
    }}>{`General guidelines`}</h2>
    <p><em parentName="p">{`Tags`}</em>{` are used for items that need to be labeled, categorized, or organized using keywords that describe them.`}</p>
    <p>{`Multiple or single tags can be used to categorize items.`}</p>
    <p>{`Use tags when content is mapped to multiple categories, and the user needs a way to differentiate between them.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.027173913043484%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAAB2UlEQVQoz61SzW7TQBBeb6+IB2h7BSoeKucceYyo4iG4YVrcIiRyjiIVoiqkqUki/6EQu4DiNHa867WL180wNs02IHFBjPRpZr7dnfl2dwhBMwyDNJtN0mg0at9qtUgURcSyLOK6LvE876/YXlcGAH9CG41G1DRN2ul0KG6meFD5brdLsZnK79bItu0gHiIe3HmN/IONx2MV7Pb7/aNer3cyHA4N7H6MXXXk9cFgoDuOo2Dbtv7pwtQ929U/e7buujZyzhGu7arK8/n8wPd9mE6nsFgsgDFWo4pns5nKkySpvRW4ECy/QbjkcB0xEGkKfhA8VQWFEI/iOM7xQIlxwTmXaZpKzCXydVxxG/59fCnPo5G0r6QMwkxmgt8mLH2iCuKmx6jmJgzDW1RQFSrRl/jTJXJ1XHMJq+MP4bDsX49LJ8jlRy8rJ362Thi/L4idD1AZFEUB6/UaNialhDzP63ibN288sIoZfF8BLMUvbpXw3668j0peocq3qOAdqjlG/wav28b3NTA/RVT8a+RPLsJJ+3JhGU6QGV9D3k55chqv2J6awf9ljPH7wd4MLl5RwzetPEXVWjXAVS4yoYlU0DzL6TPnuXb45QV9eQa0Myk0+SOlwZzVtX4Cm/AQmFXcAwwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of tags in a data table.",
            "title": "Example of tags in a data table.",
            "src": "/static/f1ff3d3c12ed8c317e34962fb51bff9d/fb070/tag-usage-2.png",
            "srcSet": ["/static/f1ff3d3c12ed8c317e34962fb51bff9d/d6747/tag-usage-2.png 288w", "/static/f1ff3d3c12ed8c317e34962fb51bff9d/09548/tag-usage-2.png 576w", "/static/f1ff3d3c12ed8c317e34962fb51bff9d/fb070/tag-usage-2.png 1152w", "/static/f1ff3d3c12ed8c317e34962fb51bff9d/c3e47/tag-usage-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <p>{`Tags can also be used as a method of filtering data, to show only items within that particular category.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.74456521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAABHUlEQVQoz6WRzUrEMBSFk7yIG5+k+BDudOHSnSufwQdwNVuh/sHMWBF3CjPVKIy4ESym6jRN006apC24GJ2aRgWpOAge+Dj3QjicS4DjOKARIQSGYYgYY4hzjpIkQVEU2Z1SivI8R2VZoqIorLewGV8O1jdWwX9VVdXH0PX2rE9EtinyrC9VvqsL6Sol96USrlTywLTraa27pkGbnuHQsPDZEIK+e2YD43G6Q595EUf8IXriWUx5OH5MUkb5fRxTXwhxKaX8zoUBGwaGxSbDOAQj7wU2SzCcrgX+6xbBbyuBP+2EV7Nl49sEz5b+cnJd161hjs5PR/bdPH6EYi8D+DiFd0MF8VEKyU0Jr08ySG41mEgOGEuA+flfadT4O9WXGFxZfpDqAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of tags in search.",
            "title": "Example of tags in search.",
            "src": "/static/1b233b204ae19f5ffa2f207f4d112294/fb070/tag-usage-1.png",
            "srcSet": ["/static/1b233b204ae19f5ffa2f207f4d112294/d6747/tag-usage-1.png 288w", "/static/1b233b204ae19f5ffa2f207f4d112294/09548/tag-usage-1.png 576w", "/static/1b233b204ae19f5ffa2f207f4d112294/fb070/tag-usage-1.png 1152w", "/static/1b233b204ae19f5ffa2f207f4d112294/c3e47/tag-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      